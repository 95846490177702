<template>
    <section class="d-flex jsutify-center align-center flex-column ma-5">
        <h3>Výběr</h3>
        <v-container class="d-flex justify-center flex-column align-center">
            <v-row>
                <v-col cols="5" sm="3">
                    <v-sheet
                        elevation="3"
                        rounded
                        class="d-flex justify-center align-center flex-column"
                        @click="redirect('mcd')"
                        aria-controls
                        width="250px"
                        height="250px"
                    >
                        <img :src="require('@/assets/mc.webp')" alt="" width="90%" class="pa-5" />
                        <span class="text-sm-caption text-md-h5">McDonald's</span>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5" sm="3">
                    <v-sheet
                        elevation="3"
                        rounded
                        class="d-flex justify-center align-center flex-column"
                        @click="redirect('bb')"
                        aria-controls
                        width="250px"
                        height="250px"
                    >
                        <img :src="require('@/assets/bb.png')" alt="" width="60%" class="pa-5" />
                        <span class="text-sm-caption text-md-h5">Bageterie Boulevard</span>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "Home",
    methods: {
        redirect(link) {
            window.location = `/${link}`;
        },
    },
};
</script>
