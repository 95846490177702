import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  position: "bottom-center",
  timeout: 2000,
};

// const url = "http://192.168.230.228:3000";
const url = "https://api.mulacovi.cz";
// const url = "http://192.168.250.19:3000";

Vue.prototype.$url = url;

Vue.config.productionTip = false;

Vue.axios.defaults.baseURL = url;
Vue.axios.defaults.headers.common["Authorization"] = localStorage.getItem("mulac_token");

Vue.use(Toast, options);

router.beforeEach((to, from, next) => {
  // if (to.name === from.name) return;
  if (to.path !== "/login" && !localStorage.getItem("mulac_token")) {
    next("/login");
  }

  if (localStorage.getItem("mulac_token") && to.path !== "/login") {
    Vue.axios.defaults.headers.common["Authorization"] = localStorage.getItem("mulac_token");
    Vue.axios
      .get("/auth")
      .then((res) => {
        if (res.status != 200) {
          next("/login");
        }
      })
      .catch((err) => {
        next("/login");
      });
  }

  next();
});

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
